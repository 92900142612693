<template>
  <div class="success">
    <div class="content">
      <SuccessIcon />
      <span>Assessment saved!</span>
      <span>
        Assessment ID:<br />
        <span class="assessment-id">{{ $route.params.id }}</span>
      </span>
      <button class="primary" @click="$router.push('/assessment-creator/create')">Create Another Assessment</button>
      <span @click="$router.push({ path: '/assessment-creator/' })" class="small">Back to Assessments</span>
    </div>
  </div>
</template>

<script>
import SuccessIcon from '@/assets/icons/success_icon.svg';

export default {
  name: 'assessment-submit-success',
  components: {
    SuccessIcon,
  },
};
</script>

<style lang="scss" scoped>
.success {
  .content {
    display: flex;
    flex-direction: column;
    margin: 100px auto;
    text-align: center;
    svg {
      display: inline-block;
      width: 50px;
      height: 50px;
      margin: 0 auto 15px auto;
    }
    span {
      margin-bottom: 25px;
      font-family: 'VWHead Light', $fallback-font-stack;
      font-size: 20px;
      &.small {
        font-family: 'VWHead Bold', $fallback-font-stack;
        font-size: 14px;
        color: $primary-light;
        cursor: pointer;
      }
    }
    button {
      display: inline-block;
      width: fit-content;
      margin: 0 auto 15px auto;
    }
  }
  .assessment-id {
    display: block;
    margin-top: 0.5em;
    font-size: 14px;
    opacity: 0.5;
  }
}
</style>
